export default function initNavigation() {

  const iconMenu = document.querySelector('.menu__icon');
  const menuBody = document.querySelector('.header__menu');
  const line = document.querySelector('.menu__line');

  if (iconMenu) {
    iconMenu.addEventListener('click', function (e) {
      document.body.classList.toggle('_lock');
      iconMenu.classList.toggle('_active');
      menuBody.classList.toggle('_active');
      line.classList.toggle('_active');
    })
  }


  window.addEventListener('scroll', function () {
    var header = document.querySelector('header');
    var scrollPosition = window.scrollY;

    if (scrollPosition > 100) { // Встановіть потрібний вам поріг скролу
      header.classList.add('scrolled'); // Додайте клас для зміни стилів
    } else {
      header.classList.remove('scrolled'); // Видаліть клас при поверненні до початкового стану
    }
  });

}
