

const swiperFunction = () => {
  let swiper = new Swiper('.swiper3', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 50,

    navigation: {
      nextEl: '.about__arrow-right',
      prevEl: '.about__arrow-left',
    },

    breakpoints: {
      767: {
        slidesPerView: 2,
      },
    },

  });

  let swiper1 = new Swiper('.swiper1', {
    direction: 'horizontal',
    slidesPerView: 1,

    pagination: {
      el: '.swiper-pagination',
    },

  });

  let swiper2 = new Swiper('.swiper2', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 35,

    navigation: {
      nextEl: '.testimonials__btn-right',
      prevEl: '.testimonials__btn-left',
    },

    breakpoints: {
      767: {
        slidesPerView: 'auto',
      },
    },

  });

  let swiper4 = new Swiper('.swiper4', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 50,
    loop: true,

    navigation: {
      nextEl: '.hero-2__arrow-right',
      prevEl: '.hero-2__arrow-left',
    },

    pagination: {
      el: '.swiper-pagination',
    },

  });

  let swiper5 = new Swiper('.swiper5', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,

    navigation: {
      nextEl: '.social__btn-right',
      prevEl: '.social__btn-left',
    },

    breakpoints: {
      991: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  });
}

export default swiperFunction;
