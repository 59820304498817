import '../scss/styles.scss';
import initNavigation from '../js/modules/navigation';
import swiperFunction from '../js/modules/swiper.js';
import initAccordion from './modules/accordion.js';



document.addEventListener('DOMContentLoaded', () => {
    initNavigation();
    swiperFunction();
    initAccordion();
});

